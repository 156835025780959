<!--
 * @Descripttion: 直播课列表
 * @version: 1.1.2pc
 * @Author: ivan
 * @Date: 2019-11-19 10:39:45
 * @LastEditors  : sueRimn
 * @LastEditTime : 2019-12-27 16:36:00
 -->
<template>
  <div class="list-wrap" :class="{ isWindowsApp: isWindowsApp }">
    <div class="control-box">
      <div class="left-box">
        <el-button type="success" v-if="newBtn" @click.stop="toCreatePage">
          新建小班课
        </el-button>
        <div class="number">
          <div class="t1">
            叮豆数量：{{ userInfo.t_amount }}
            <div
              v-if="isJiGouAdmin && showRecordBtn"
              class="record"
              @click="record"
            >
              使用记录
            </div>
            <div v-if="showRechargeBtn" class="recharge" @click="recharge">
              充值叮豆
            </div>
          </div>
          <div class="t2">
            1对1授课预计可用{{
              userInfo.t_amount == 0 ? 0 : Math.floor(userInfo.t_amount / 0.5)
            }}分钟
          </div>
        </div>
      </div>

      <div class="center_contain">
        <div class="right-box">
          <div class="select-wrap">
            <el-select
              placeholder="全部类型"
              v-model="requestParam.price_type"
              @change="getPriceType"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </div>
          <div class="select-wrap">
            <el-select
              placeholder="全部状态"
              v-model="requestParam.status"
              @change="getStatus"
            >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </div>
          <div class="input-wrap">
            <el-input
              v-model="requestParam.title"
              placeholder="输入课程名称搜索"
              @keyup.enter.native="doSeach"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="course-list">
      <CourseItem
        :list="list"
        :post2="requestParam"
        parentPath="/coursePrivate"
      ></CourseItem>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import CourseItem from '@/components/course/CourseItem'

export default {
  data() {
    return {
      emptyStatusHeihgt: '',
      requestParam: {
        course_type: '2', //课程类型 1直播 2小班 3录播 4系列
        price_type: '', //收费方式 1 付费 2 免费 3 密码
        status: '', //课程上/下架状态 1上架 2 下架
        title: '', //搜索关键词
        order: '',
        sort: '',
      },
      resultInfo: {},
      options: [
        {
          value: '',
          label: '付费类型',
        },
        {
          value: '2',
          label: '免费',
        },
        {
          value: '1',
          label: '付费',
        },
        {
          value: '3',
          label: '密码',
        },
      ],
      options2: [
        {
          value: '',
          label: '全部状态',
        },
        {
          value: '1',
          label: '已上架',
        },
        {
          value: '2',
          label: '已下架',
        },
      ],
      isNoData: false,
      // 显示加载图标
      isLoading: false,
    }
  },
  components: {
    CourseItem,
  },
  computed: {
    ...mapState(['isWindowsApp', 'userInfo']),
    ...mapGetters(['isJiGouAdmin']),

    showRechargeBtn() {
      return this.$store.state.roots.includes(138)
    },

    showRecordBtn() {
      return this.$store.state.roots.includes(154)
    },

    //新建
    newBtn() {
      return this.$store.state.roots.includes(177)
    },

    //管理
    namagerBtn() {
      return this.$store.state.roots.includes(179)
    },

    //上下架
    upDownBtn() {
      return this.$store.state.roots.includes(181)
    },

    //删除
    delBtn() {
      return this.$store.state.roots.includes(183)
    },

    list() {
      return {
        namagerBtn: this.namagerBtn,
        upDownBtn: this.upDownBtn,
        delBtn: this.delBtn,
      }
    },
  },
  methods: {
    // 充值叮豆
    recharge() {
      if (this.isJiGouAdmin) {
        this.$router.push('/capitalAccount/TopUpDingDou')
      } else {
        this.$root.prompt({
          msg: '请联系管理员充值',
        })
      }
    },

    // 使用记录
    record() {
      this.$router.push('/capitalAccount?type=2')
    },

    toCreatePage() {
      this.$router.push({
        path: '/coursePrivate/AddCourse',
        query: {
          courseType: 2,
        },
      })
    },
    toggleShowCopy() {
      this.isShowCopy = !this.isShowCopy
    },
    // 获取付费类型
    getPriceType(value) {
      this.requestParam.price_type = value
      this.requestParam.page = 1
    },
    // 获取课程状态
    getStatus(value) {
      this.requestParam.status = value
      this.requestParam.page = 1
    },
    //关键字搜索
    doSeach(e) {
      const keyCode = window.event ? e.keyCode : e.which
      if (keyCode == 13) {
        this.requestParam.page = 1
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.list-wrap {
  padding: 0 20px;
  background: #fff;
  .title-list {
    display: flex;
    padding: 12px 20px;
    background: #f5f5f5;
    overflow: hidden;
    .title-inner {
      display: flex;
      align-items: center;
      min-width: 508px;
      p {
        position: relative;
      }
    }
    p {
      font-size: 14px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
    }
    .p1 {
      flex: 1;
      min-width: 323px;
      left: -10px;
    }
    .p2 {
      width: 104px;
      left: -14px;
    }
    .p3 {
      width: 151px;
      left: -10px;
    }
    .p4 {
      width: 134px;
      left: -10px;
    }
    .p5 {
      flex: 1;
      min-width: 176px;
      text-align: right;
    }
  }
  .control-box {
    padding: 20px 0 20px;
    .center_contain {
      margin-top: 30px;
      align-items: center;
      display: flex;
      justify-content: flex-end;
      .switch_font {
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
    }
    .left-box {
      display: flex;
      align-items: center;
      ::v-deep .el-button--success {
        color: #fff;
        background-color: #0aa29b;
        border-color: #0aa29b;
      }
      .number {
        margin-left: 20px;
        .t1 {
          width: 285px;
          font-size: 14px;
          line-height: 19px;
          color: rgba(51, 51, 51, 1);
        }
        .t2 {
          font-size: 12px;
          line-height: 16px;
          margin-top: 8px;
          color: rgba(102, 102, 102, 1);
        }
        .recharge,
        .record {
          float: right;
          font-size: 14px;
          font-weight: bold;
          line-height: 19px;
          cursor: pointer;
          color: rgba(10, 162, 155, 1);
        }
        .recharge {
          margin-left: 10px;
        }
        .record {
          margin-left: 10px;
        }
      }
    }
    .right-box {
      display: flex;
      .select-wrap {
        width: 110px;
        margin-right: 10px;
      }
      .input-wrap {
        width: 150px;
      }
    }
  }
  .pagination-wrap {
    padding: 30px 0;
    text-align: center;
  }
}
.isWindowsApp {
  .title-list .p1 {
    min-width: 278px;
  }
  .title-list .title-inner {
    width: 463px;
  }
  .title-list .p2 {
    width: 66px;
  }
  .title-list .p3 {
    width: 81px;
  }
  .title-list .p4 {
    width: 88px;
  }
  .title-list .p5 {
    text-align: left;
    position: relative;
    left: 72px;
  }
  .title-list {
    padding-right: 20px;
  }
}
// @media screen and (max-width: 1210px) {
//   .list-wrap .title-list .p1 {
//     min-width: 278px;
//   }
//   .list-wrap .title-list .title-inner {
//     width: 463px;
//   }
//   .list-wrap .title-list .p2 {
//     width: 66px;
//   }
//   .list-wrap .title-list .p3 {
//     width: 81px;
//   }
//   .list-wrap .title-list .p4 {
//     width: 88px;
//   }
//   .list-wrap .title-list .p5 {
//     text-align: left;
//     position: relative;
//     left: 72px;
//   }
//   .list-wrap .title-list {
//     padding-right: 20px;
//   }
// }
</style>
